import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSubmit } from "@remix-run/react";
import classNames from "classnames";
import { useDebounce } from "~/hooks/use_debounce";
import { type Option, type Options, Select } from "~/components/select";
import "./filters.scss";
import type { FilterConfiguration, BoardLayout } from "~/types/jben/board_configuration";
import { TextInput } from "~/components/text_input";
import { type Department } from "~/types/jben/department";
import { type Office } from "~/types/jben/office";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import type { CustomField } from "~/types/jben/custom_field";

interface FiltersProps {
  departments: Department[];
  offices: Office[];
  customFields: CustomField[];
  departmentIds?: string[];
  officeIds?: string[];
  customFieldFilters?: Record<string, string[]>;
  keyword?: string | undefined;
  config: FilterConfiguration;
  boardLayoutConfiguration: BoardLayout;
}

const updateSearchParams = (search: string, param: string, value: string | string[]) => {
  const searchParams = new URLSearchParams(search);
  searchParams.delete(param);

  if (Array.isArray(value)) {
    value.forEach((v) => searchParams.append(param, v));
  } else if (value) {
    searchParams.append(param, value);
  }

  return searchParams;
};

const toOptions = (
  list: (Department | Office)[],
  depth = 0
): Array<{ value: number; label: string; depth: number }> => {
  return list.flatMap((item) => {
    const option = { value: item.id, label: item.name, depth: depth };

    if (item.children && item.children.length > 0) {
      return [option].concat(toOptions(item.children, depth + 1));
    } else {
      return option;
    }
  });
};

const StickyContainer = ({
  boardLayoutConfiguration,
  children,
}: {
  boardLayoutConfiguration: BoardLayout;
  children: React.ReactNode;
}) => {
  if (boardLayoutConfiguration !== "side_by_side") {
    return children;
  }

  const stickyContainerClasses = classNames({
    "filters__column-sticky-container": boardLayoutConfiguration === "side_by_side",
  });

  return <div className={stickyContainerClasses}>{children}</div>;
};

const Filters = (props: FiltersProps) => {
  let { t } = useTranslation("board");
  const [keyword, setKeyword] = useState(props.keyword || "");
  const debouncedKeyword = useDebounce(keyword, 250);
  const previousKeyword = useRef(keyword);

  const { outside_label, display_department_hierarchy } = useBoardConfiguration();

  const {
    departments = [],
    offices = [],
    customFields = [],
    officeIds,
    departmentIds,
    customFieldFilters,
    config,
  } = props;
  const departmentOptions = toOptions(departments);
  const officeOptions = toOptions(offices);

  const submit = useSubmit();
  const location = useLocation();

  const applySearch = useCallback(
    (searchParams: URLSearchParams) => {
      searchParams.delete("page");
      submit(searchParams, { preventScrollReset: true });
    },
    [submit]
  );

  useEffect(() => {
    if (debouncedKeyword === previousKeyword.current) {
      return;
    }

    previousKeyword.current = debouncedKeyword;

    const searchParams = updateSearchParams(location.search, "keyword", debouncedKeyword);
    applySearch(searchParams);
  }, [debouncedKeyword, location.search, applySearch]);

  const handleSelect = (
    selected: Option | Readonly<Options> | null,
    queryParam: "offices[]" | "departments[]" | `field_${number}[]`
  ) => {
    const value = Array.isArray(selected)
      ? selected.map((option) => option.value)
      : ((selected as Option).value as string);
    const searchParams = updateSearchParams(location.search, queryParam, value);
    applySearch(searchParams);
  };

  const selectedDepartments = departmentOptions.filter((option) =>
    departmentIds?.includes(option.value.toString())
  );
  const selectedOffices = officeOptions.filter((option) =>
    officeIds?.includes(option.value.toString())
  );

  const selectedCustomFieldOptions: { [key: string]: Option[] } = {};
  if (customFields && customFieldFilters) {
    customFields.forEach((field) => {
      if (customFieldFilters[field.id]) {
        if (field.options) {
          selectedCustomFieldOptions[field.id] = field.options
            .filter((option) => customFieldFilters[field.id].includes(option.id.toString()))
            .map((option) => ({ label: option.name, value: option.id }));
        }
      }
    });
  }

  const filterClasses = classNames({
    filters: true,
    filters__column: props.boardLayoutConfiguration === "side_by_side",
  });

  return (
    <div className={filterClasses}>
      <StickyContainer boardLayoutConfiguration={props.boardLayoutConfiguration}>
        <TextInput
          id="keyword-filter"
          label={t("filters.search.label")}
          placeholder={t("filters.search.placeholder")}
          required={false}
          value={keyword}
          onChange={(e) => setKeyword(e.currentTarget.value)}
          maxLength={255}
          outsideLabel={outside_label}
        />

        {config.include_department_filter && (
          <Select
            isMulti
            id="department-filter"
            label={t("filters.department")}
            options={departmentOptions}
            grouped={display_department_hierarchy}
            isClearable={false}
            selected={selectedDepartments}
            onSelect={(option) => handleSelect(option, "departments[]")}
            outsideLabel={outside_label}
          />
        )}
        {config.include_office_filter && (
          <Select
            isMulti
            id="office-filter"
            label={t("filters.office")}
            options={officeOptions}
            grouped={true}
            isClearable={false}
            selected={selectedOffices}
            onSelect={(option) => handleSelect(option, "offices[]")}
            outsideLabel={outside_label}
          />
        )}

        {config.allow_custom_field_filters &&
          customFields.map((field) => {
            const options =
              field.options?.map((option) => ({
                value: option.id,
                label: option.name,
              })) || [];
            return (
              <Select
                key={field.id}
                isMulti
                id={`custom-field-${field.id}`}
                label={field.name}
                options={options}
                selected={selectedCustomFieldOptions[field.id]}
                onSelect={(option) => handleSelect(option, `field_${field.id}[]`)}
                outsideLabel={outside_label}
              />
            );
          })}
      </StickyContainer>
    </div>
  );
};

export default Filters;
