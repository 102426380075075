import type { QuickApplyProfile } from "./types";

const TIMEOUT_S = 120;

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(`Unauthorized: ${message}`);
    this.name = "UnauthorizedError";
  }
}

export class QuickApplyClient {
  private url: string;
  private loginWindow: Window | null;

  constructor(url: string) {
    this.url = url;
    this.loginWindow = null;
  }

  public async fetchProfile(): Promise<QuickApplyProfile> {
    this.validateUrl();

    const response = await fetch(`${this.url}/users/self`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      return await response.json();
    }

    if (response.status === 401) {
      throw new UnauthorizedError("User is not logged in.");
    }

    throw new Error(`Failed to fetch profile: ${response.status} ${response.statusText}`);
  }

  public initiateLoginFlow(): void {
    // We use an empty string for the URL so the window doesn't get focused.
    // We set the URL later, once we know the user is not logged in.
    this.loginWindow = window.open("", "_blank");
  }

  public endLoginFlow(): void {
    this.loginWindow?.close();
  }

  public async fetchProfileAfterLogin(): Promise<QuickApplyProfile | null> {
    this.validateUrl();

    return new Promise((resolve) => {
      async function handleMessage(this: QuickApplyClient, event: MessageEvent) {
        if (!this.isRelevantMessage(event)) {
          return;
        }

        if (this.isLoginSuccessfulMessage(event)) {
          window.removeEventListener("message", handleMessage.bind(this));
          this.endLoginFlow();
          resolve(await this.fetchProfile());
        }
      }

      window.addEventListener("message", handleMessage.bind(this));
      this.setUrlOnLoginWindow();

      // If the login takes too long, we can assume the user has abandoned the process and resolve with null.
      setTimeout(() => {
        window.removeEventListener("message", handleMessage.bind(this));
        resolve(null);
      }, TIMEOUT_S * 1000);
    });
  }

  private isRelevantMessage(event: MessageEvent): boolean {
    return event.origin === this.url;
  }

  private isLoginSuccessfulMessage(event: MessageEvent): boolean {
    return event.data === "loginSuccessful";
  }

  private validateUrl(): void {
    if (!this.url) {
      throw new Error("Quick Apply URL is not set.");
    }
  }

  private setUrlOnLoginWindow(): void {
    if (!this.loginWindow) {
      return;
    }

    this.loginWindow.location = `${this.url}/users/sign_in?initiator=autofill`;
  }
}
