import "./button.scss";
import classNames from "classnames";
import { LoadingSpinner } from "./loading_spinner";

export type ButtonShape = "rectangle" | "rounded" | "pill";
type ButtonType = "submit" | "button" | "reset";

interface ButtonProps {
  children: string;
  shape?: ButtonShape | null;
  type?: ButtonType;
  secondary?: boolean;
  tertiary?: boolean;
  ariaLabel?: string;
  link?: boolean;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  testId?: string;
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    shape = "pill",
    type = "button",
    secondary = false,
    tertiary = false,
    ariaLabel,
    link = false,
    href,
    loading,
    disabled,
    onClick,
    testId,
  } = props;

  const className = classNames({
    btn: !tertiary,
    [`btn--${shape}`]: !tertiary,
    "btn--secondary": !tertiary && secondary,
    "btn--tertiary": tertiary,
    btn__disabled: disabled,
  });

  if (link) {
    return (
      <a href={href} target="_top" className={className} aria-label={ariaLabel}>
        {children}
      </a>
    );
  }

  return (
    <button
      type={type}
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      aria-disabled={disabled}
      data-testid={testId}
    >
      {loading && <LoadingSpinner size="small" />}
      {!loading && children}
    </button>
  );
};
