import { createContext } from "react";
import { QuickApplyClient } from "../client";
import { type TrackingMetadata } from "../types";

interface QuickApplyContextValue {
  quickApplyActive: boolean;
  quickApplyClient: QuickApplyClient;
  quickApplyMagicActive: boolean;
  metadata?: TrackingMetadata;
}

export const QuickApplyContext = createContext<QuickApplyContextValue>({
  quickApplyActive: false,
  quickApplyClient: new QuickApplyClient(""),
  quickApplyMagicActive: false,
});
