import "./tag.scss";

export interface TagProps {
  label: string;
  tagStyle: string;
  spacing?: boolean;
}

export const Tag = ({ label, tagStyle, spacing }: TagProps) => {
  return (
    <span className={`tag-container ${tagStyle}`} style={{ marginLeft: spacing ? 8 : 0 }}>
      <span className="ellipse">
        <span className="tag-text">{label}</span>
      </span>
    </span>
  );
};
