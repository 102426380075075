import type { SyntheticEvent } from "react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./file_upload.scss";
import { SecondaryBody } from "./typography";
import { TextArea } from "./text_area";
import FileIcon from "./icons/file";
import CloseIcon from "./icons/close";
import { IconButton } from "./icon_button";
import { Button } from "./button";
import type UploadManager from "~/utils/uploads/upload_manager";
import classNames from "classnames";
import { type UploadedFile } from "~/utils/uploads/uploaded_file";
import GoogleDrivePicker from "~/utils/google_drive_picker.client";
import { isClient } from "~/utils/env_check";
import { useDropboxChooser } from "~/hooks/use_dropbox_chooser";
import { useUpdateEffect } from "react-use";
import { Resizer } from "~/utils/resizer";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";

export interface FileUploadData {
  file?: UploadedFile;
  text?: string;
}

interface FileUploadProps {
  id: string;
  manualInputId?: string;
  label: string;
  uploadManager: UploadManager;
  filetypes?: string[];
  required?: boolean;
  allowS3?: boolean;
  allowManual?: boolean;
  loadDropbox?: boolean;
  loadGoogleDrive?: boolean | null;
  errorMessage?: string;
  internal?: boolean;
  onChange: (value: FileUploadData) => void;
  fileName?: string;
}

const FileUpload = (props: FileUploadProps) => {
  const [showManualUploader, setShowManualUploader] = useState(false);
  const [fileName, setFileName] = useState(props.fileName || "");
  const [uploading, setUploading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [manualInput, setManualInput] = useState("");
  const [uploadError, setUploadError] = useState("");

  const { button_shape } = useBoardConfiguration();
  const inputRef = useRef<HTMLInputElement>(null);
  const googleDriveRef = useRef<HTMLAnchorElement>(null);

  const {
    id,
    manualInputId,
    label,
    uploadManager,
    filetypes = ["pdf", "doc", "docx", "txt", "rtf"],
    required = false,
    allowS3 = false,
    allowManual = false,
    loadDropbox = false,
    loadGoogleDrive = false,
    errorMessage = "",
    onChange,
  } = props;

  const { t } = useTranslation("job_post");

  useEffect(() => {
    if (percentage >= 100 && fileName) {
      setUploading(false);
    }
  }, [fileName, percentage]);

  useEffect(() => {
    setFileName(props.fileName || "");
  }, [props.fileName]);

  useUpdateEffect(() => {
    Resizer.getInstance().handleResize();
  }, [showManualUploader, uploading]);

  const setUpload = useCallback(
    (name: string, url: string) => {
      setFileName(name);
      setShowManualUploader(false);
      setManualInput("");
      onChange({ file: { name, url } });
    },
    [onChange]
  );

  const uploadToS3 = useCallback(
    async (file: any) => {
      const uploader = uploadManager.uploaders[id];
      await uploader.uploadFile(file, setPercentage);

      setUpload(file.name, uploader.fileUrl());
    },
    [id, uploadManager, setUpload]
  );

  const { openDropboxChooser, dropbox } = useDropboxChooser(loadDropbox);

  const pickerRef = useRef<GoogleDrivePicker | null>(null);

  if (isClient() && !pickerRef.current) {
    pickerRef.current = new GoogleDrivePicker({
      trigger: googleDriveRef.current,
      uploadToS3,
      setUploadError,
      setUploading,
    });
  }

  const openFileDialog = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();
    inputRef.current?.click();
  };

  const megabytesToBytes = (mb: number) => mb * 1024 * 1024;

  const attachFile = async (e: any) => {
    setUploading(true);

    const file = e.target?.files[0];
    const MAX_FILE_SIZE = 100;

    if (!file) {
      return;
    }

    if (file.size > megabytesToBytes(MAX_FILE_SIZE)) {
      setUploading(false);
      setUploadError(t("file_upload.invalid_file_size", { file_size: MAX_FILE_SIZE }) || "");
      return;
    }

    const uploader = uploadManager.uploaders[id];

    try {
      await uploader.uploadFile(file, setPercentage);
    } catch (e) {
      setUploading(false);
      setPercentage(0);
      onChange({});
      if (e instanceof Error) setUploadError(e.message);
      return;
    }

    setUpload(file.name, uploader.fileUrl());
  };

  const removeFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setFileName("");
    setPercentage(0);
    onChange({});
  };

  const handleManualUploadToggleClick = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();

    setShowManualUploader(!showManualUploader);
  };

  const handleManualInputChange = (value: string) => {
    setManualInput(value);
    onChange({
      text: value,
    });
  };

  const handleDropboxUploadClick = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();

    setUploadError("");

    if (!dropbox.loaded()) {
      setUploadError(t<string>("file_upload.try_again", { uploader: "Dropbox" }));
      return;
    }

    if (!dropbox.browserSupported()) {
      setUploadError(t<string>("file_upload.unsupported_browser", { uploader: "Dropbox" }));
      return;
    }

    openDropboxChooser(filetypes || ["documents", "text"], async (files) => {
      if (files.length === 0) {
        return;
      }

      const dropboxResponse = await fetch(files[0].link);
      const blob = await dropboxResponse.blob();

      setUploading(true);
      const file = new File([blob], files[0].name, { type: blob.type });
      await uploadToS3(file);
    });
  };

  const attachControl = (
    <div>
      <Button
        shape={button_shape}
        type="button"
        aria-label={t("file_upload.aria.attach", { label: label.toLowerCase() }) || ""}
        aria-describedby={`accepted-filetypes ${id}-description ${id}-error`}
        data-error={!!errorMessage}
        onClick={openFileDialog}
      >
        {t("file_upload.attach")}
      </Button>
      <label className="visually-hidden" htmlFor={id}>
        {t("file_upload.attach")}
      </label>
      <input
        id={id}
        className="visually-hidden"
        type="file"
        accept={filetypes.map((type) => `.${type}`).join(",")}
        ref={inputRef}
        onChange={attachFile}
      />
    </div>
  );

  const dropboxControl = (
    <Button
      shape={button_shape}
      type="button"
      onClick={handleDropboxUploadClick}
      aria-label={
        t("file_upload.aria.label", {
          label: label.toLowerCase(),
          uploader: "Dropbox",
        }) || ""
      }
      testId={`${id}-dropbox`}
    >
      {t("file_upload.dropbox")}
    </Button>
  );

  const googleDriveControl = (
    <Button
      shape={button_shape}
      href="#"
      onClick={() => pickerRef.current?.onClick()}
      aria-label={
        t("file_upload.aria.label", {
          label: label.toLowerCase(),
          uploader: "Google Drive",
        }) || ""
      }
    >
      {t("file_upload.google")}
    </Button>
  );

  const manualControl = (
    <div>
      <Button
        shape={button_shape}
        type="button"
        aria-label={t("file_upload.aria.manual", { label: label.toLowerCase() }) || ""}
        aria-describedby={`${id}-description ${id}-error`}
        onClick={handleManualUploadToggleClick}
        testId={`${id}-text`}
      >
        {t("file_upload.manually")}
      </Button>
      <label className="visually-hidden" htmlFor={manualInputId}>
        {t("file_upload.manually")}
      </label>
    </div>
  );

  const renderControls = () => {
    const controls = [attachControl];

    if (loadDropbox) {
      controls.push(dropboxControl);
    }

    if (loadGoogleDrive) {
      controls.push(googleDriveControl);
    }

    if (allowManual) {
      controls.push(manualControl);
    }

    return controls;
  };

  const controls = renderControls();

  const hasError = errorMessage || uploadError;

  const labelClasses = classNames({
    label: true,
    "upload-label": true,
    "upload-label--error": !!hasError,
  });

  const labelId = `upload-label-${id}`;

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <div
      role="group"
      aria-labelledby={labelId}
      aria-required={required}
      className="file-upload"
      data-allow-s3={allowS3}
    >
      <div id={labelId} className={labelClasses}>
        {label}
        {required && <span className="required">*</span>}
      </div>
      <div className="file-upload__wrapper">
        {!uploading && !fileName && (
          <div className={"button-container"}>
            {controls.map((control, i) => (
              <div key={i} className="secondary-button">
                {control}
              </div>
            ))}
            <p id="accepted-filetypes" className="file-upload__filetypes">
              {t("file_upload.filetypes", { file_types: filetypes.join(", ") })}
            </p>
          </div>
        )}

        {!uploading && fileName && (
          <div className="file-upload__filename">
            <FileIcon />
            <SecondaryBody>{fileName}</SecondaryBody>
            <IconButton
              label="Remove file"
              icon={CloseIcon}
              size="sm"
              onClick={removeFile}
            ></IconButton>
          </div>
        )}
        {uploading && (
          <div
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin={0}
            aria-valuemax={100}
            className="file-upload__progressbar"
          >
            <span style={{ width: percentage + "%" }}></span>
          </div>
        )}
      </div>

      {allowManual && showManualUploader && (
        <TextArea
          id={manualInputId}
          aria-describedby={`${id}-description ${id}-error`}
          aria-invalid={!!errorMessage}
          aria-errormessage={`${id}-error`}
          aria-required={required}
          rows={6}
          onChange={(event) => handleManualInputChange(event.currentTarget.value)}
          outsideLabel={true}
          value={manualInput}
        />
      )}

      {hasError && (
        <p id={`${id}-error`} className="helper-text helper-text--error" aria-live="polite">
          {errorMessage} {uploadError}
        </p>
      )}
    </div>
  );
};

export default FileUpload;
