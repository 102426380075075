import { GreenhouseLogo } from "./greenhouse_logo";
import { Body, Link } from "./typography";
import "./footer.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
        <Body>Powered by</Body>
        <a
          className="footer-logo-link"
          target="_blank"
          href="http://www.greenhouse.io/"
          rel="noreferrer"
        >
          <GreenhouseLogo height={30} width={100} />
        </a>
      </div>
      <div className="footer-row">
        <Body>
          Read our{" "}
          <Link target="_blank" href="http://www.greenhouse.io/privacy-policy" rel="noreferrer">
            Privacy Policy
          </Link>
        </Body>
      </div>
    </footer>
  );
};
