import "./typography.scss";
import classNames from "classnames";

interface PageHeaderProps {
  children: React.ReactNode;
}

const PageHeader = ({ children }: PageHeaderProps) => {
  return (
    <h1
      className="page-header font-primary"
      dangerouslySetInnerHTML={{ __html: children as string }}
    />
  );
};

type SectionHeaderProps = {
  as?: "h1" | "h2" | "h3";
  children: React.ReactNode;
  large?: boolean;
  testId?: string;
};

const SectionHeader = (props: SectionHeaderProps) => {
  const { as, children, large, testId } = props;

  const className = classNames({
    "section-header": true,
    "section-header--large": large,
    "font-primary": true,
  });

  return as === "h1" ? (
    <h1 className={className} data-testid={testId}>
      {children}
    </h1>
  ) : as === "h2" ? (
    <h2 className={className} data-testid={testId}>
      {children}
    </h2>
  ) : (
    <h3 className={className} data-testid={testId}>
      {children}
    </h3>
  );
};

type BodyProps = {
  medium?: boolean;
  metadata?: boolean;
  strikethrough?: boolean;
  status?: "default" | "active";
  secondary?: boolean;
  children: React.ReactNode;
};

const Body = (props: BodyProps) => {
  const { medium, metadata, strikethrough, status, secondary, children } = props;

  const className = classNames({
    body: true,
    body__secondary: secondary,
    "body--metadata": metadata,
    "body--strikethrough": strikethrough,
    "body--medium": medium,
    "body--active": status === "active",
  });

  return <p className={className}>{children}</p>;
};

type DivProps = {
  children: React.ReactNode;
};

const BodyDiv = (props: DivProps) => <div className="body">{props.children}</div>;

const SecondaryBodyDiv = (props: DivProps) => (
  <div className="body body__secondary">{props.children}</div>
);

type SecondaryBodyProps = {
  medium?: boolean;
  metadata?: boolean;
  strikethrough?: boolean;
  children: React.ReactNode;
};

const SecondaryBody = (props: SecondaryBodyProps) => {
  return <Body {...props} secondary={true} />;
};

type LinkProps = {
  href?: string;
  children: React.ReactNode;
  rel?: string;
  target?: string;
  linkref?: React.RefObject<HTMLAnchorElement>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const Link = (props: LinkProps) => {
  return (
    <a className={"link"} ref={props.linkref} target="_top" {...props}>
      {props.children}
    </a>
  );
};

type LabelProps = {
  htmlFor: string;
  id: string;
  children: React.ReactNode;
  required?: boolean;
  className?: string;
};

const Label = (props: LabelProps) => {
  const className = classNames("label", props.className);

  return (
    <label id={props.id} htmlFor={props.htmlFor} className={className}>
      {props.children}
      {props.required && <span aria-hidden="true">*</span>}
    </label>
  );
};

const TableHeader = ({ children }: { children: React.ReactNode }) => {
  return <p className="table-header font-primary">{children}</p>;
};

export {
  SectionHeader,
  Body,
  BodyDiv,
  PageHeader,
  SecondaryBody,
  SecondaryBodyDiv,
  Link,
  Label,
  TableHeader,
};
