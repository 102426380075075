import React, { useMemo } from "react";
import { QuickApplyContext } from "./contexts/quick_apply_context";
import { QuickApplyClient } from "./client";
import { type TrackingMetadata } from "./types";

interface Props {
  active: boolean;
  children: React.ReactNode;
  magicActive: boolean;
  url: string;
  metadata: TrackingMetadata;
}

export const QuickApply = ({ active, children, magicActive, url, metadata }: Props) => {
  const client = useMemo(() => new QuickApplyClient(url), [url]);

  const contextValue = useMemo(
    () =>
      Object.freeze({
        quickApplyActive: active,
        quickApplyClient: client,
        quickApplyMagicActive: magicActive,
        metadata,
      }),
    [active, client, magicActive, metadata]
  );

  return <QuickApplyContext.Provider value={contextValue}>{children}</QuickApplyContext.Provider>;
};
