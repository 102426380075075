import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./job_alert.scss";
import { Body, Link } from "./typography";
import ReminderIcon from "./icons/reminder";

export const JobAlert = ({ urlToken, companyName }: { urlToken: string; companyName: string }) => {
  const { t } = useTranslation("job_post");
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/users/sign_in?job_board=${urlToken}`);
  }, [urlToken]);

  return (
    <div className="job-alert">
      <ReminderIcon />
      <div>
        <Body medium>{t("job_alert.title")}</Body>
        <Body>
          {t("job_alert.description", { companyName, interpolation: { escapeValue: false } })}
        </Body>
        <Link target="_blank" href={jobSeekersUrl} rel="noreferrer">
          {t("job_alert.create")}
        </Link>
      </div>
    </div>
  );
};
