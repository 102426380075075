import { useTranslation } from "react-i18next";
import { BodyDiv, Link } from "~/components/typography";
import type { ButtonShape } from "~/components/button";
import { Button } from "~/components/button";
import { Logo } from "~/components/logo";
import type { LogoConfiguration } from "~/types/jben/board_configuration";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { useEffectOnce } from "react-use";

interface ConfirmationProps {
  internal: boolean;
  urlToken: string;
  jobPostId: string;
  jobPostPublicUrl?: string;
  companyName: string;
  message: string;
  buttonShape: ButtonShape | null;
  logoConfiguration: LogoConfiguration;
}

const Confirmation = (props: ConfirmationProps) => {
  const { t } = useTranslation("confirmation");
  const { internal, urlToken, jobPostId, companyName, message, buttonShape, logoConfiguration } =
    props;
  const { withTrackingParams } = useTrackingParams();
  const { job_board_public_url } = useBoardConfiguration();

  const jobBoardHref = job_board_public_url;
  const jobPostHref =
    props.jobPostPublicUrl ||
    (internal ? `/internal_job_board/applications/${jobPostId}` : `/${urlToken}/jobs/${jobPostId}`);

  useEffectOnce(() => {
    window.parent.postMessage("greenhouse.confirmation", "*");
  });

  return (
    <div className="confirmation">
      <Logo
        url={logoConfiguration.url}
        href={logoConfiguration.href && withTrackingParams(logoConfiguration.href)}
        companyName={companyName}
      />

      <BodyDiv>
        <span dangerouslySetInnerHTML={{ __html: message }}></span>
      </BodyDiv>

      <div className="confirmation__links">
        <Button link href={withTrackingParams(jobBoardHref)} shape={buttonShape}>
          {t("view_more_jobs", { companyName, interpolation: { escapeValue: false } })}
        </Button>
        <Link href={withTrackingParams(jobPostHref)}>{t("back")}</Link>
      </div>
    </div>
  );
};

export default Confirmation;
