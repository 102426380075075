import { Body, BodyDiv, Link, SectionHeader } from "../typography";
import { Button } from "../button";
import "./job_post.scss";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../icons/arrow_left";
import type { PayRange, HiringTeamMembers } from "~/types/jben/job_post";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { Tag, type TagProps } from "../tag";
import { useHiringTeamRenderer } from "~/hooks/use_hiring_team_renderer";
import { BadgePanel } from "./job_trust_profiles/badge_panel";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { JobAlert } from "~/components/job_alert";
import LocationIcon from "../icons/location";

interface Props {
  conclusion: string;
  content: string;
  introduction: string;
  location: string;
  onApplyClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  title: string;
  internal?: boolean;
  payRanges?: PayRange[];
  tags?: TagProps[];
  hiringTeamMembers?: HiringTeamMembers;
  hiringPlanId?: number | string | null;
  urlToken?: string;
  companyName?: string;
  allowJobAlert?: boolean;
}

export const JobPost = ({
  conclusion,
  content,
  introduction,
  location,
  onApplyClick,
  title,
  internal,
  payRanges,
  tags,
  hiringTeamMembers = { hiring_managers: [], recruiters: [] },
  hiringPlanId,
  urlToken,
  companyName,
  allowJobAlert = false,
}: Props) => {
  const { t } = useTranslation("job_post");
  const {
    button_shape,
    cta_text,
    view_all_link,
    display_hiring_team,
    job_board_public_url,
    hide_app_description,
  } = useBoardConfiguration();
  const { buildRecruiterText, buildHiringManagerText } = useHiringTeamRenderer();
  const { withTrackingParams } = useTrackingParams();

  const hiringManagerText = buildHiringManagerText(hiringTeamMembers.hiring_managers);
  const recruiterText = buildRecruiterText(hiringTeamMembers.recruiters);

  const handleApplyClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!onApplyClick) {
      return;
    }

    onApplyClick(event);
  };

  const renderPayRanges = () =>
    payRanges?.map((payRange) => (
      <div className="pay-range" key={payRange.title}>
        {payRange.description && (
          <div className="description">
            <BodyDiv>
              <span dangerouslySetInnerHTML={{ __html: payRange.description }}></span>
            </BodyDiv>
          </div>
        )}
        <Body medium>{payRange.title}</Body>
        <Body>
          {payRange.min} - {payRange.max} {payRange.currency_type}
        </Body>
      </div>
    ));

  const showPayRanges = payRanges && payRanges.length > 0;

  const showHiringTeamMembers = !!(
    internal &&
    display_hiring_team &&
    (hiringManagerText || recruiterText)
  );

  const renderHiringTeamMembers = () => (
    <div className="job__contacts">
      <Body medium>{t("post.contacts")}</Body>
      {hiringManagerText && <Body>{hiringManagerText}</Body>}
      {recruiterText && <Body>{recruiterText}</Body>}
    </div>
  );

  return (
    <div>
      {!hide_app_description && (
        <>
          {view_all_link && (
            <Link href={withTrackingParams(job_board_public_url)}>
              <ArrowLeftIcon />
              {t("post.back")}
            </Link>
          )}
          <div className="job__tags">
            {tags?.map((tag, index) => (
              <Tag key={index} label={tag.label} tagStyle={"featured"} />
            ))}
          </div>
          <div className="job__header">
            <div className="job__title">
              <SectionHeader as="h1" large>
                {title}
              </SectionHeader>
              {location && (
                <div className="job__location">
                  <LocationIcon size="sm" />
                  <div>{location}</div>
                </div>
              )}
            </div>
            {onApplyClick && (
              <Button
                ariaLabel={t("post.apply") || ""}
                onClick={handleApplyClick}
                shape={button_shape || undefined}
              >
                {cta_text || t("post.apply")}
              </Button>
            )}
          </div>
          <div className="job__description body">
            {showHiringTeamMembers && renderHiringTeamMembers()}
            {introduction && <div dangerouslySetInnerHTML={{ __html: introduction }}></div>}
            {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
            {showPayRanges && <div className="job__pay-ranges">{renderPayRanges()}</div>}
            {conclusion && <div dangerouslySetInnerHTML={{ __html: conclusion }}></div>}
          </div>
        </>
      )}
      <BadgePanel hiringPlanId={hiringPlanId} />
      {allowJobAlert && <JobAlert urlToken={urlToken || ""} companyName={companyName || ""} />}
    </div>
  );
};
