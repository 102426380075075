import { type FieldValue } from "~/components/job_posts/field";
import { useUrlToken } from "~/hooks/use_url_token";
import type { JobPost, Question } from "~/types/jben/job_post";
import { Autofiller } from "../autofill/autofiller";
import { useEffect, useMemo, useRef } from "react";
import { type EducationAnswer } from "~/components/job_posts/education_section";
import { EducationAutofiller } from "../autofill/education_autofiller";
import { useMonthOptions } from "~/hooks/use_month_options";
import { type EmploymentAnswer } from "~/utils/build_application";
import { EmploymentAutofiller } from "../autofill/employment_autofiller";
import { useSnowplowTracker } from "~/hooks/use_snowplow_tracker";
import { useQuickApply } from "./use_quick_apply";

export const useAutofillerRef = (
  answers: Record<string, FieldValue>,
  educationAnswers: EducationAnswer[],
  employmentAnswers: EmploymentAnswer[],
  jobPost: JobPost,
  questions: Question[],
  hasEeocSection: boolean,
  setAnswers: (answers: Record<string, FieldValue>) => void,
  setEducationAnswers: (answers: EducationAnswer[]) => void,
  setEmploymentAnswers: (answers: EmploymentAnswer[]) => void
) => {
  const urlToken = useUrlToken();
  const monthOptions = useMonthOptions();
  const tracker = useSnowplowTracker();
  const { metadata } = useQuickApply();

  const memoizedAutofiller = useMemo(
    () =>
      new Autofiller(
        answers,
        setAnswers,
        questions,
        new EducationAutofiller(
          urlToken,
          educationAnswers,
          setEducationAnswers,
          monthOptions,
          jobPost.education_config
        ),
        hasEeocSection,
        new EmploymentAutofiller(employmentAnswers, setEmploymentAnswers, jobPost.employment),
        metadata,
        tracker
      ),
    [
      answers,
      educationAnswers,
      employmentAnswers,
      monthOptions,
      questions,
      setAnswers,
      hasEeocSection,
      setEducationAnswers,
      setEmploymentAnswers,
      urlToken,
      jobPost.education_config,
      jobPost.employment,
      tracker,
      metadata,
    ]
  );

  const autofillerRef = useRef<Autofiller>(memoizedAutofiller);

  useEffect(() => {
    autofillerRef.current = memoizedAutofiller;
  }, [memoizedAutofiller]);

  return autofillerRef;
};
