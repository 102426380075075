import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import "./quick_apply_flash.scss";
import { Body, Link } from "../components/typography";
import { IconButton } from "../components/icon_button";
import CloseIcon from "../components/icons/close";
import CheckIcon from "../components/icons/check";
import ErrorIcon from "../components/icons/error";
import PreviewIcon from "~/components/icons/preview";
import { useTranslation } from "react-i18next";

type QuickApplyFlashProps = {
  type: "success" | "error";
  closeFlash: () => void;
  embedded?: boolean;
};

export const QuickApplyFlash = ({ type, closeFlash, embedded }: QuickApplyFlashProps) => {
  const { t } = useTranslation("job_post");

  const [shouldRender, setShouldRender] = useState(false);
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/profile`);
  }, []);

  useEffect(() => {
    setShouldRender(true);
  }, [setShouldRender]);

  if (!shouldRender) return null;

  let icon = <CheckIcon color="green" />;
  let message = t("application.quick_apply.success");

  if (type === "error") {
    icon = <ErrorIcon color="red" />;
    message = t("application.quick_apply.error");
  }

  const mountElement = window.document.getElementById("react-portal-mount-point");

  if (embedded) {
    window.parent.postMessage({ type: "autofill", editProfileLink: jobSeekersUrl }, "*");
    return null;
  }

  if (!mountElement) return null;

  return (
    <>
      {createPortal(
        <div className="quick-apply-flash">
          <span>{icon}</span>
          <div className="flash-body">
            <Body>{message}</Body>
            <div className="job-seekers-link">
              <Link href={jobSeekersUrl} target="_blank">
                {t("application.quick_apply.edit_profile")}
              </Link>
              <PreviewIcon color="green" size="xs" />
            </div>
          </div>
          <IconButton
            label="Close flash"
            icon={CloseIcon}
            size="sm"
            onClick={closeFlash}
          ></IconButton>
        </div>,
        mountElement
      )}
    </>
  );
};
